<template>
  <content-wrapper class="piligrim">
    <img src="@/assets/img/serafim.png" class="serafim" />

    <h1>
      Памятка
      <br />поломника
    </h1>

    <div class="content">
      <h2>В паломническо-туристи-ческий кластер "Серафимова земля" входят – Арзамас, Дивеево, Саров, Выездное и Ореховец</h2>
      <h2>Транспорт</h2>
      <p>Для удобного перемещения между основными открытыми для свободного посещения населенными пунктами кластера – Арзамасом и Дивеево лучше всего выбрать автобус.</p>

      <p>Автовокзал, с которого можно доехать до села Дивеево, находится на станции Арзамас-2. Автобусы из Арзамаса ходят почти каждый час. Первый автобус отходит в 5-30, последний в 17-30.</p>

      <p>Из Серафимо-Дивеевского монастыря в 19-00 каждый день отправляется автобус на железнодорожные вокзалы в Арзамас. В пути автобус находится менее полутора часов.</p>

      <p>Тел. автовокзала — 8 (831-47) 4-18-14</p>

      <p>
        Также, можно воспользоваться
        <br />местной службой такси:
        <br />+7 950-36-44-900
        <br />+7 920-02-03-976
      </p>

      <p>Для передвижения по закрытому городу Саров можно использовать сервис «Яндекс-такси», а также городской общественный транспорт.</p>

      <h2>Медицинская помощь</h2>

      <p>
        Арзамас
        <br />ГБУЗ НО ЦГБ, ул. Зеленая, д 2
        <br />тел: +7 (83147) 6-05-56
      </p>

      <p>
        Дивеево
        <br />ГБУЗ Но Дивеевская ЦРБ им. АК. Н. Н. Блохина, ул. Космонавтов 1А
        <br />тел: +7 (83134) 4-28-90
      </p>

      <p>
        Саров
        <br />Клиническая больница № 50 Федераль-ного Медико-биологического Агентства, ул. Зерновая 72,
        <br />тел: +7 (83130) 6-00-71
      </p>

      <p>
        В Дивеево в монастыре паломникам можно пообедать в бесплатной паломнической трапезной
        <br />Расписание работы бесплатной паломнической трапезной: обед с 11.00 до 13.00, ужин с 20.00 до 21.00.
      </p>
    </div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";

export default {
  components: { ContentWrapper }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.piligrim {
  position: relative;
  background: $grad_blue;

  // &::before {
  //   content: "";
  //   width: 518px;
  //   height: 930px;
  //   background: url(/img/serafim.png) no-repeat left bottom;
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  // }
  .serafim {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  h1 {
    position: absolute;
    top: 56px;
    left: 32px;
    font-size: 50px;
    font-weight: bold;
    line-height: 1.12;
    color: rgba(#fff, 0.97);
  }
  .content {
    position: absolute;
    right: 12px;
    width: 500px;
    height: 1192px;
    overflow-y: scroll;
    padding-top: 16px;
    padding-right: 12px;

    &::-webkit-scrollbar-track {
      margin-bottom: 56px;
      margin-top: 16px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(#fff, 0.2);
    }

    h2 {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.13;
      letter-spacing: -1.02px;
      color: rgba(#fff, 0.97);
      margin-bottom: 24px;
      margin-top: 56px;
    }

    p {
      font-size: 24px;
      line-height: 1.5;
      letter-spacing: -0.1px;
      color: rgba(#fff, 0.97);
      margin-bottom: 24px;
      &:last-child {
        margin: 56px 0;
      }
    }
  }
}
</style>
